<template>
    <div class="page-gray">
        <div class="list" style="padding-top: 1px;">
            <div class="card" v-for="item in list">
                <div class="item van-hairline--bottom">
                    <label for="">单品名称:</label>
                    <div>{{item.name}}</div>
                </div>
                <div class="item van-hairline--bottom">
                    <label for="">单价:</label>
                     <van-field  v-model="item.money" label="¥" label-width="10" placeholder="请输入价格" type="number" />
                </div>
                 <div class="item van-hairline--bottom">
                    <label for="">打包数量:</label>
                    <van-field  v-model="item.count" placeholder="请输入排序" type="digit" />
                </div>
            </div>
        </div>
        <div class="button">
            <van-button type="primary" @click="submit" block>保存</van-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "SetUniform",
    data() {
        return {
            list: [{
                name: '长沙市雨花区砂子塘万境第二小学（夏季套装）男款',
                money: '60',
                count: 1
            },{
                name: '长沙市雨花区砂子塘万境第二男款长沙市雨花区砂子塘万境第二小学男款长沙市雨花区',
                money: '60',
                count: 1
            },{
                name: '长沙市雨花区砂子塘万境第二小学（夏季套装）男款',
                money: '60',
                count: 1
            }]
        }
    },

    methods: {
        submit() {
            for (let i = 0; i < this.list.length; i++) {
                const e = this.list[i];
                if (e.money === '' || e.money === undefined) {
                    this.$toast(`第${i + 1}件单品 请输入单价`);
                    return
                }

                if (e.count === '' || e.count === undefined ) {
                    this.$toast(`第${i + 1}件单品 请输入打包数量`);
                    return
                }
              
                
            }
            this.$toast.success('保存成功');
            setTimeout(() => {
                this.$router.back()
            }, 1500)
    
        }
    }
}

</script>
<style lang="less" scoped>
.card {
    background: #FFFFFF;
    border-radius: 5px;
    margin: 10px 18px;
    padding: 10px 0 0;

    .item {
        padding: 12px 16px;
        label {
            display: block;
            margin-bottom: 5px;
        }

        > div {
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
        }
        .van-cell {
            margin-top: 7px;
            padding: 0;
        }
    }
}
.button {
    padding: 10px 18px;

}
::v-deep .van-field__control, ::v-deep .van-field__label {
    font-size: 13px;
    color: #212121;
    margin-right: 0;
}
</style>
